// import Whitepaper from './Whitepaper';

const data = [
  {
    text: 'exhibitions',
    link: '#exhibitions',
  },
  {
    text: 'experiences',
    link: '#experiences',
  },
  // {
  //   text: 'token',
  //   link: '#tokenomics',
  // },
  // {
  //   text: 'roadmap',
  //   link: '#roadmap',
  // },
  // {
  //   text: 'whitepaper',
  //   suffix: 'fa-solid fa-file-pdf',
  //   link: Whitepaper.href,
  //   target: '_blank',
  // },
  {
    text: 'team',
    link: '#team',
  },
  {
    text: 'contact',
    link: '#contact',
  },
  // {
  //   text: 'explore',
  //   link: 'https://mvp.nftmuseum.io/3d-spaces/index.html',
  //   button: true,
  // },
];

export default data;
