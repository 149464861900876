const data = [
  {
    title: "DECONBOX",
    video: "https://artetra.io/wp-content/uploads/2022/07/DECONBOX_720p.mp4",
    videoFormat: "mp4",
    poster: "https://artetra.io/wp-content/uploads/2022/07/deconbox.webp",
    linkDesktop: {
      url: "https://artetra.io/wp-content/uploads/2022/07/DECONBOX_1080p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkMobile: {
      url: "https://artetra.io/wp-content/uploads/2022/07/DECONBOX_720p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkYouTube: {
      url: "https://youtu.be/rXfZj4yku4w",
      target: "_blank",
      icon: "fa-brands fa-youtube",
    },
    author: {
      avatar:
        "https://artetra.io/wp-content/uploads/2022/02/nftmuseum-water-edge-1.png",
      name: "@Mrchair",
    },
  },
  {
    title: "SERENITY",
    video: "https://artetra.io/wp-content/uploads/2022/07/SERENITY_720p.mp4",
    videoFormat: "mp4",
    poster:
      "https://artetra.io/wp-content/uploads/2022/07/nftmuseum-mzl1-2-1.webp",
    linkDesktop: {
      url: "https://artetra.io/wp-content/uploads/2022/07/SERENITY_1080p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkMobile: {
      url: "https://artetra.io/wp-content/uploads/2022/07/SERENITY_720p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkYouTube: {
      url: "https://youtu.be/5g_53ASuzdc",
      target: "_blank",
      icon: "fa-brands fa-youtube",
    },
    target: "_blank",
    author: {
      avatar:
        "https://artetra.io/wp-content/uploads/2022/03/nftmuseum-serenity-3.png",
      name: "@cozen",
    },
  },
  {
    title: "LUNAR",
    video: "https://artetra.io/wp-content/uploads/2022/07/LUNAR_720p.mp4",
    videoFormat: "mp4",
    poster:
      "https://artetra.io/wp-content/uploads/2022/07/nftmuseum-mzl1-3-1.webp",
    linkDesktop: {
      url: "https://artetra.io/wp-content/uploads/2022/07/LUNAR_1080p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkMobile: {
      url: "https://artetra.io/wp-content/uploads/2022/07/LUNAR_720p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkYouTube: {
      url: "https://youtu.be/jtDE_iLNQYY",
      target: "_blank",
      icon: "fa-brands fa-youtube",
    },
    target: "_blank",
    author: {
      avatar:
        "https://artetra.io/wp-content/uploads/2022/02/nftmuseum-dot-lunar-scape-2-1.png",
      name: "@Mrchair",
    },
  },
  {
    title: "ROUND TABLE",
    video: "https://artetra.io/wp-content/uploads/2022/07/ROUND_TABLE_720p.mp4",
    videoFormat: "mp4",
    poster:
      "https://artetra.io/wp-content/uploads/2022/07/nftmuseum-mzl1-4-1.webp",
    linkDesktop: {
      url: "https://artetra.io/wp-content/uploads/2022/07/ROUND_TABLE_1080p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkMobile: {
      url: "https://artetra.io/wp-content/uploads/2022/07/ROUND_TABLE_720p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkYouTube: {
      url: "https://youtu.be/vkFbYGj5Th0",
      target: "_blank",
      icon: "fa-brands fa-youtube",
    },
    target: "_blank",
    author: {
      avatar:
        "https://artetra.io/wp-content/uploads/2022/02/nftmuseum-dot-round-table-1.png",
      name: "@cozen",
    },
  },
  {
    title: "SHADOWS",
    video: "https://artetra.io/wp-content/uploads/2022/07/SHADOWS_720p.mp4",
    videoFormat: "mp4",
    poster:
      "https://artetra.io/wp-content/uploads/2022/07/nftmuseum-mzl1-5-1.webp",
    linkDesktop: {
      url: "https://artetra.io/wp-content/uploads/2022/07/SHADOWS_1080p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkMobile: {
      url: "https://artetra.io/wp-content/uploads/2022/07/SHADOWS_720p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkYouTube: {
      url: "https://youtu.be/45MUVVC0XOY",
      target: "_blank",
      icon: "fa-brands fa-youtube",
    },
    target: "_blank",
    author: {
      avatar:
        "https://artetra.io/wp-content/uploads/2022/02/nftmuseum-shadows-1.png",
      name: "@MateiTosa",
    },
  },
  {
    title: "MELODY",
    video: "https://artetra.io/wp-content/uploads/2022/07/MELODY_720p.mp4",
    videoFormat: "mp4",
    poster:
      "https://artetra.io/wp-content/uploads/2022/07/nftmuseum-mzl1-6-1.webp",
    linkDesktop: {
      url: "https://artetra.io/wp-content/uploads/2022/07/MELODY_1080p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkMobile: {
      url: "https://artetra.io/wp-content/uploads/2022/07/MELODY_720p.mp4",
      target: "_blank",
      icon: "fa-solid fa-circle-play",
    },
    linkYouTube: {
      url: "https://youtu.be/fm1v9-Cwowc",
      target: "_blank",
      icon: "fa-brands fa-youtube",
    },
    target: "_blank",
    author: {
      avatar:
        "https://artetra.io/wp-content/uploads/2022/02/nftmuseum-serenity-2-1.png",
      name: "@GeorgesMazilu",
    },
  },
];

export default data;
