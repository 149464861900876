import React from "react";

function Anchor(props) {
  return (
    <div className="anchor">
      <div id={props.id} />
    </div>
  );
}

export default Anchor;
