import menu from './Menu';
import exhibitions from './Exhibitions';
import team from './Team';
import advisors from './Advisors';
import experience from './Experience';
import partners from './Partners';
import social from './Social';
import tokenomics from './Tokenomics';
import roadmap from './Roadmap';
import whitepaper from './Whitepaper';

export const Menu = menu;
export const Exhibitions = exhibitions;
export const Team = team;
export const Advisors = advisors;
export const Experience = experience;
export const Partners = partners;
export const Social = social;
export const Roadmap = roadmap;
export const Tokenomics = tokenomics;
export const Whitepaper = whitepaper;
