const data = [
  {
    icon: 'twitter',
    label: 'Twitter',
    link: 'https://twitter.com/Artetra_io',
  },
  {
    icon: 'linkedin-in',
    label: 'LinkedIn',
    link: 'https://www.linkedin.com/company/artetra-io',
  },
  {
    icon: 'facebook',
    label: 'Facebook',
    link: 'https://www.facebook.com/artetra.io',
  },
  {
    icon: 'instagram',
    label: 'Instagram',
    link: 'https://www.instagram.com/artetra.io/',
  },
  {
    icon: 'envelope',
    label: 'Email',
    link: 'mailto:info@artetra.io',
  }
];

export default data;
