import React from 'react';
import './styles/App.scss';
import {
  Menu,
  Exhibitions,
  // Team,
  Social,
} from './data';
import Anchor from './components/Anchor';
import GetAccessForm from './components/GetAccessForm';

function App() {
  function IsDesktop() {
    return window.innerWidth >= 1200;
  }

  function playVideo(index) {
    if (IsDesktop()) {
      const video = document.getElementById(`exhibition-video-${index}`);
      video.play();
    }
  }

  function pauseVideo(index) {
    if (IsDesktop()) {
      const video = document.getElementById(`exhibition-video-${index}`);
      video.pause();
    }
  }

  function menuClick() {
    document.getElementById('menu').open = false;
  }

  return (
    <div className='App'>
      <header>
        <div className='container'>
          <div className='mobile-padding' />
          <a href='/' className='logo'>
            <img
              src='https://artetra.io/wp-content/uploads/2022/12/Artetra-Logo.webp'
              alt='ARTETRA'
            />
          </a>
          <nav className='desktop'>
            <ul>
              {Menu.map((item, index) => (
                <li key={index}>
                  <a
                    href={item.link}
                    target={item.target}
                    role={item.button ? 'button' : null}
                  >
                    {item.prefix ? (
                      <i className={`prefix ${item.prefix}`} />
                    ) : null}
                    {item.text}
                    {item.suffix ? (
                      <i className={`suffix ${item.suffix}`} />
                    ) : null}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <nav className='mobile'>
            <details id='menu'>
              <summary>
                <span className='hidden-text'>Menu</span>
                <i className='menu-closed fa-solid fa-bars' />
                <i className='menu-open fa-solid fa-xmark' />
              </summary>
              <ul>
                {Menu.map((item, index) => (
                  <li key={index}>
                    <a
                      className={`menu-item ${item.button ? 'button' : null}`}
                      role={item.button ? 'button' : null}
                      href={item.link}
                      target={item.target}
                      onClick={() => menuClick()}
                    >
                      {item.prefix ? (
                        <i className={`prefix ${item.prefix}`} />
                      ) : null}
                      {item.text}
                      {item.suffix ? (
                        <i className={`suffix ${item.suffix}`} />
                      ) : null}
                    </a>
                  </li>
                ))}
                <li className='click-out'>
                  <button className='menu-item' onClick={() => menuClick()}>
                    Close menu
                  </button>
                </li>
              </ul>
            </details>
          </nav>
        </div>
      </header>

      <main>
        {/* HERO */}
        <div className='section banner hero dark' id='hero'>
          <div className='container'>
            <div className='layer mountains' />
            <div className='layer halo' />
            <div className='layer dark' />
            <div className='layer content'>
              <div className='container'>
                <h1>Create, Trade, Experience</h1>
                <p className='h4'>
                  Sell, trade and exhibit works or products directly in our virtual exhibition spaces
                </p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: GetAccessForm,
                  }}
                />

                {/* <p className='h3 elrond'>
                  <span>Powered by </span>
                  <a href='https://elrond.com/' target='_blank' rel='noreferrer noopener'>
                    <img
                      src='https://artetra.io/wp-content/uploads/2022/07/elrond-logo-light.webp'
                      alt='Elrond'
                    />
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>

        {/* Partners */}
        {/* <div className='section slim partners'>
          <div className='container'>
            <h2 className='center h4'>Our partners:</h2>
            <div className='logos'>
              {Partners.map((company, index) => (
                <a
                  key={index}
                  href={company.link}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <img src={company.logo} alt={company.alt} />
                </a>
              ))}
            </div>
          </div>
        </div> */}

        {/* Exhibitions */}
        <div className='section exhibitions'>
          <Anchor id='exhibitions' />
          <div className='container'>
            <div className='header'>
              <h2>Latest Exhibitions</h2>
              <p>Get a glimpse at the latest 3D galleries</p>
            </div>
            <div className='content'>
              {Exhibitions.map((exhibition, index) => (
                <React.Fragment key={index}>
                  {/* Desktop: */}
                  <a
                    id={`exhibition-${index}`}
                    href={exhibition.linkDesktop.url}
                    target={exhibition.linkDesktop.target}
                    className='article exhibition-video-link'
                    onMouseEnter={() => playVideo(index)}
                    onFocus={() => playVideo(index)}
                    onMouseLeave={() => pauseVideo(index)}
                    onBlur={() => pauseVideo(index)}
                  >
                    <video
                      id={`exhibition-video-${index}`}
                      muted='muted'
                      loop
                      preload='metadata'
                      poster={exhibition.poster}
                    >
                      <source
                        src={exhibition.video}
                        type={`video/${exhibition.videoFormat}`}
                      />
                    </video>

                    <div className='play-icon'>
                      <i className={exhibition.linkDesktop.icon} />
                    </div>

                    <div className='text'>
                      <img
                        className='avatar'
                        src={exhibition.author.avatar}
                        alt=''
                      />
                      <span className='name'>{exhibition.author.name}</span>
                      <span className='title'>{exhibition.title}</span>
                    </div>
                  </a>
                  {/* Mobile: */}
                  <a
                    id={`exhibition-${index + Exhibitions.length}`}
                    href={exhibition.linkMobile.url}
                    target={exhibition.linkMobile.target}
                    className='article exhibition-image-link'
                  >
                    <img
                      src={exhibition.poster}
                      alt={`${exhibition.title} exhibition`}
                    />

                    <div className='play-icon'>
                      <i className={exhibition.linkMobile.icon} />
                    </div>

                    <div className='text'>
                      <img
                        className='avatar'
                        src={exhibition.author.avatar}
                        alt=''
                      />
                      <span className='name'>{exhibition.author.name}</span>
                      <span className='title'>{exhibition.title}</span>
                    </div>
                  </a>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        {/* >Your Collection In One Place */}
        <div
          className='section banner slider your-collection-in-one-place'
          id='your-collection-in-one-place'
        >
          <div className='container'>
            <div className='layer background' />
            <div className='layer slides'>
              <div className='layer slide' />
              <div className='layer slide' />
              <div className='layer slide' />
              <div className='layer slide' />
              <div className='layer slide' />
            </div>
            <div className='layer vignette' />
            <div className='layer content'>
              <div className='text-container'>
                <div className='text'>
                  <h2>Your Collection In One Place</h2>
                  <p className='big'>
                    Showcase all your collection in virtual spaces for a global visibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Immersive Experiences */}
        <div className='section exhibitions'>
          <Anchor id='experiences' />
          <div className='container'>
            <div className='header'>
              <h2>Immersive Experiences</h2>
              <p>
                Seamlessly integrate with virtual reality tools and create a
                unique multimedia experience.
              </p>
            </div>
            <div className='content'>
              <img
                src='https://artetra.io/wp-content/uploads/2022/07/nftmuseum-vr-phone-dark-2.webp'
                alt='VR AR 360'
              />
            </div>
          </div>
        </div>

        {/* From Minting To Marketplace */}
        <div className='section banner slider from-minting-to-marketplace'>
          <div className='container'>
            <div className='layer background' />
            <div className='layer slides'>
              <div className='layer slide' />
              <div className='layer slide' />
              <div className='layer slide' />
              <div className='layer slide' />
              <div className='layer slide' />
            </div>
            <div className='layer vignette' />
            <div className='layer content'>
              <div className='text-container'>
                <div className='text'>
                  <h2>
                    Sell directly in our virtual exhibition spaces
                  </h2>
                  <p className='big'>
                    Find your audience on our unique marketplace in a frictionless and efficient way.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Token utility */}
        {/* <div className='section tokenomics'>
          <Anchor id='tokenomics' />
          <div className='container'>
            <h2 className='center'>Token utility</h2>
            <div className='content'>
              <div className='token-utility'>
                <div className='items'>
                  {Tokenomics.map((item, index) => (
                    <div key={index}>
                      <img className='icon' src={item.image} alt='' />
                      <p className='text'>{item.title}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Tokenomics */}
        {/* <div className='section tokenomics'>
          <Anchor id='tokenomics' />
          <div className='container'>
            <h2 className='center'>$Tetra Tokenomics</h2>
            <div className='content'>
              <div className='chart center'>
                <img
                  src='https://artetra.io/wp-content/uploads/2022/12/tokenomics-1.webp'
                  alt='Tokenomics Chart'
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* Roadmap */}
        {/* <div className='section roadmap'>
          <Anchor id='roadmap' />
          <div className='container'>
            <h2 className='center'>Roadmap</h2>
            {Roadmap.map((quarter, index) => (
              <React.Fragment key={index}>
                <div className={`quarter ${index % 2 ? 'left' : 'right'}`}>
                  <div className='time-wrap'>
                    <div className='time'>
                      {quarter.checked ? (
                        <div className='checked'>✓</div>
                      ) : (
                        <div>
                          <span>COMING</span>
                          <span>{quarter.year}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <ul className='milestones'>
                    {quarter.milestones.map((milestone, index) => (
                      <li key={index}>{milestone}</li>
                    ))}
                  </ul>
                </div>
                {quarter.quarter === 4 ? <div className='year'></div> : null}
              </React.Fragment>
            ))}
          </div>
        </div> */}

        {/* Whitepaper */}
        {/* <div className='section slim whitepaper light'>
          <div className='container'>
            <div className='header'>
              <h2>Read Our Whitepaper</h2>
              <p>For a deeper look into our project</p>
            </div>

            <a
              href={Whitepaper.href}
              role='button'
              target={Whitepaper.target}
              rel='noreferrer noopener'
            >
              WHITEPAPER <i className={`suffix fa-solid fa-file-pdf`} />
            </a>
          </div>
        </div> */}

        {/* Team */}
        {/* <div className='section team'>
          <Anchor id='team' />
          <div className='container'>
            <h2 className='center'>Team</h2>
            {Team.map((member, index) => (
              <a
                key={index}
                className='profile'
                href={member.link}
                target='_blank'
                rel='noreferrer noopener'
              >
                <div className='image'>
                  <img
                    src={member.image}
                    alt={`${member.name}, ${member.title}`}
                  />
                </div>
                <div className='content'>
                  <h3 className='name'>{member.name}</h3>
                  <span className='title'>{member.title}</span>
                  <span className='description'>{member.description}</span>
                </div>
              </a>
            ))}
          </div>
        </div> */}

        {/* Advisors */}
        {/* <div className='section team'>
          <div className='container'>
            <h2 className='center'>Advisors</h2>
            {Advisors.map((member, index) => (
              <a
                key={index}
                className='profile'
                href={member.link}
                target='_blank'
                rel='noreferrer noopener'
              >
                <div className='image'>
                  <img
                    src={member.image}
                    alt={`${member.name}, ${member.title}`}
                  />
                </div>
                <div className='content'>
                  <h3 className='name'>{member.name}</h3>
                  <span className='title'>{member.title}</span>
                  <span className='description'>{member.description}</span>
                </div>
              </a>
            ))}
          </div>
        </div> */}

        {/* Experience */}
        {/* <div className="section slim experience">
          <div className="container">
            <h2 className="center h4">Combined Company Experience:</h2>
            <div className="logos">
              {Experience.map((company, index) => (
                <img key={index} src={company.logo} alt={company.alt} />
              ))}
            </div>
          </div>
        </div> */}

        {/* Social */}
        <div className='section social' id='contact'>
          <div className='container'>
            <h2 className='center h3'>Join us on social media:</h2>
            {Social.map((channel, index) => (
              <div key={index} className='channel'>
                <a
                  href={channel.link}
                  aria-label={channel.label}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <i className={`fa-brands fa-${channel.icon} ${channel.icon === 'envelope' ? 'rotate-icon' : ''}`}></i>
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Motto */}
        <div className='section motto'>
          <div className='container'>
            <h2 className='center h3'>Creation is only the beginning</h2>
            <div className='logos'></div>
          </div>
        </div>
      </main>

      <footer>
        <a href='https://artetra.io'>Artetra.io</a>
        <span>All Rights Reserved</span>
        <a
          href='https://artetra.io/wp-content/uploads/2022/08/2022_06_10_Artetra_TC_Website_BR.pdf'
          target='_blank'
          rel='noreferrer noopener'
        >
          Terms of Use <i className='fa-solid fa-file-pdf' />
        </a>
      </footer>
    </div>
  );
}

export default App;
